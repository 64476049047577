export default defineComponent({
  props: {
    outcome: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {},
  methods: {
    getIndicators: function getIndicators(outcome) {
      return this.$store.getters['assessment/getIndicators'](outcome.id);
    }
  }
});